    <!--已审核待支付-->
<template>
  <div class="hosptial-cost-page">
    <div class="cost-page-header">
      <div class="item-cell">
        <div class="cell-name">
          住院号:
        </div>
        <div class="cell-value">
          {{ detail.admissionNo }}
        </div>
      </div>
      <div class="item-cell">
        <div class="cell-name">
          科室名称:
        </div>
        <div class="cell-value">
          {{ detail.currentDeptName }}
        </div>
      </div>
      <div class="item-cell">
        <div class="cell-name">
          已预缴:
        </div>
        <div class="cell-value">
          {{ detail.deposit }}元
        </div>
      </div>
      <div class="item-cell">
        <div class="cell-name">
          押金余额:
        </div>
        <div class="cell-value">
          {{ detail.depositBalance }}元
        </div>
      </div>
    </div>
  
    <div class="cost-page-money flex-center">
      <div class="page-money-title">
        预缴金额
      </div>
      <div class="page-money-value">
        <input v-model="money" class="page-money-input" placeholder="请输入缴费金额">
      </div>
      <div class="page-money-tips">
        可以多次预缴付款
      </div>
    </div>
    <div class="cost-page-bottom">
      <van-button class="cell-btn" type="primary" round size="medium" @click="debounceCreateOrder">
        立即预缴
      </van-button>
    </div>
  </div>
</template>
<script>
    import { gjcModel } from '@/api/gjcApi'
    import { Toast } from 'vant'
    import {debounce} from '../../../utils/index'
    export default {
        data() {
            return {
              patientDict: {},
              detail: {},
              money:''
            }
        },
        created(){
           this.patientDict=JSON.parse(this.$store.getters.patient)
        },
        mounted() {
         
          this.getDetail()
        },
        methods: {
          getDetail() {
            const params = {
                hospCode: '', //医院代码
                patientCardId:this.patientDict.patientCardList[0].patientCardId,
                patientInfoId:this.patientDict.patientId
            }
            gjcModel.gjcZyOnLinePay(params).then(res => {
              if (res.code === 200) {
                this.detail = res.data
              }
            })
          },
          debounceCreateOrder:debounce(function(){
            this.createOrder()
          },500,true),
          createOrder() {
            if(!this.money || !this.isPositiveInteger(this.money)){
              this.$toast('请输入预缴金额')
              return
            }
            Toast.loading({
              mask: true,
              duration: 0,
              message: "加载中..."
            })
            const params = {
                ...this.detail,
                patientCardId:this.patientDict.patientCardList[0].patientCardId,
                patientInfoId:this.patientDict.patientId,
                depositInAdvance:this.money
            }
            gjcModel.gjcZyOnLinePayCreateOrder(params).then(result => {
              Toast.clear()
              if (result.code === 200) {
                this.$router.push({
                  path:'/orderPay',
                  query:{
                      id:result.data.orderId,
                      type:'14'
                  }
                })
              }
            }).catch(()=>{
              Toast.clear()
            })
          },
          isPositiveInteger(n) {
            // 使用正则表达式判断参数是否为大于0的正整数
            const regex = /^[1-9]\d*$/
            return regex.test(n)
          }
        }
    }
</script>
<style scoped lang='less'>
@import "../../../assets/styles/variable.less";
.hosptial-cost-page{
  background: #fff;
}
.cost-page-header{
  padding: 24px 200px;
  background: #fff;
  border-bottom: 1px solid #ccc;
  margin-bottom: 24px;
}
.item-cell{
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 26px;
  .cell-part{
      display: flex;
      flex: 1;
        align-items: center;
      .cell-name{
        font-weight: 500;
      }
      .cell-value{
          margin-left: 12px;
      }
  }
}
.cost-page-bottom{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 24px;
  background: #fff;
  .cell-btn{
      width: 100%;
  }
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cost-page-money{
  width: 100%;
}
.page-money-title{
  font-size: 28px;
  font-weight: bold;
  color: @dark-font-color;
  margin-bottom: 24px;
}
.page-money-input{
  height: 60px;
  width: 450px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 12px;
  font-size: 26px;
}
.page-money-tips{
  font-size: 18px;
  color: #999;
  margin-top: 6px;
  text-align: right;
  width: 450px;
}
</style>
    